<template>
  <form id="loginform">
    <div>
      <dl>
        <dt><label for="username">ユーザー名</label></dt>
        <dd>
          <input
            type="text"
            class="form-control"
            id="username"
            placeholder="ユーザー名"
            v-model="authData.username"
          >
        </dd>
      </dl>
      <dl>
        <dt><label for="password">パスワード</label></dt>
        <dd>
          <input
            type="password"
            class="form-control"
            id="password"
            placeholder="パスワード"
            v-model="authData.password"
          >
        </dd>
      </dl>
      <div class="sleeve">
        <button
          class="w-100 btn btn-lg btn-primary"
          type="submit"
          @click.prevent="login"
        >ログイン</button>
      </div>
      <p>たんぽぽ歯科ミニドック管理 ver.{{ currentVersion }}</p>
    </div>
  </form>
</template>

<script>
export default {
  name: 'LoginDialogue',
  data () {
    return {
      authData: {
        username: null,
        password: null
      },
      currentVersion: this.$store.getters.currentVersion
    }
  },
  mounted () {
    if (this.$store.getters['Authorize/authData']) {
      this.authData = this.$store.getters['Authorize/authData']
    }
    this.$store.watch(
      (state, getters) => getters.currentVersion,
      (newValue) => {
        this.currentVersion = newValue
      }
    )
  },
  methods: {
    login () {
      this.$store.commit('Authorize/authData', this.authData)
      this.$store.dispatch('Authorize/login', this.authData)
    }
  }
}
</script>
