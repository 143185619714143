import { createStore } from 'vuex'
import Authorize from './Authorize.js'
import Log from './Log.js'
import Patient from './Patient.js'
import Staff from './Staff.js'

export default createStore({
  state: {
    currentVersion: ''
  },
  getters: {
    currentVersion: (state) => state.currentVersion
  },
  mutations: {
    currentVersion (state, currentVersion) {
      state.currentVersion = currentVersion
    }
  },
  actions: {},
  modules: {
    Authorize,
    Log,
    Patient,
    Staff
  }
})
